/* General Reset */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif; /* Modern font family */
  background-color: #0d1117; /* Dark, elegant background */
  color: #c9d1d9; /* Soft white for text */
  line-height: 1.6;
}

/* App Container */
.App {
  text-align: left;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Header Section */
.App-header {
  margin-bottom: 50px;
  text-align: center;
}

.App-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  color: #ffffff; /* Bold white for the name */
}

.App-header h2 {
  font-size: 1.25rem;
  font-weight: 400;
  color: #8b949e; /* Subtle gray for the title */
  margin-top: 10px;
}

/* Section Styles */
.Section {
  margin-bottom: 40px;
}

.Section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #ffffff;
  border-left: 4px solid #58a6ff; /* Blue accent for the title */
  padding-left: 10px;
}

.Section-content {
  font-size: 1rem;
  color: #c9d1d9;
  white-space: pre-wrap; /* Preserve line breaks */
}

/* Footer */
.App-footer {
  margin-top: 50px;
  text-align: center;
}

.App-footer a {
  color: #58a6ff; /* Accent blue for links */
  text-decoration: none;
  font-weight: 500;
  margin: 0 10px;
  transition: color 0.2s ease-in-out;
}

.App-footer a:hover {
  color: #79c0ff; /* Lighter blue on hover */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .App {
    padding: 15px;
  }

  .App-header h1 {
    font-size: 2rem;
  }

  .App-header h2 {
    font-size: 1rem;
  }

  .Section-title {
    font-size: 1rem;
  }

  .Section-content {
    font-size: 0.9rem;
  }
}
